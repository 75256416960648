<div *ngIf="errors?.['required']">
  {{ requiredError() }}
</div>

<div *ngIf="errors?.['minlength']">
  {{ minlengthError() }}
</div>

<div *ngIf="errors?.['maxlength']">
  {{ maxlengthError() }}
</div>

<div *ngIf="errors?.['min']">
  {{ minError() }}
</div>

<div *ngIf="errors?.['max']">
  {{ maxError() }}
</div>

<div *ngIf="errors?.['json']">
  {{ jsonError() }}
</div>

<div *ngIf="errors?.['parameter-name']">
  {{ parameterNameError() }}
</div>

<div *ngIf="errors?.['mime-type-format']">
  {{ mimeTypeFormatError() }}
</div>

<div *ngIf="errors?.['email']">
  {{ emailError() }}
</div>

<div *ngIf="errors?.['password-confirm']">
  {{ passwordConfirmError() }}
</div>

<div *ngIf="errors?.['allowed-characters']">
  {{ allowedCharactersError() }}
</div>
