export const ActiveStorageAttachmentType = 'active_storage_attachments';
export const ApiKeyType = 'api_keys';
export const AutomationRuleType = 'automation_rules';
export const BatchType = 'batches';
export const CommentType = 'comments';
export const ConfigurationType = 'configurations';
export const CrmAddressType = 'addresses';
export const CrmCompanyType = 'companies';
export const DocumentChangeType = 'document_changes';
export const DocumentDownloadType = 'document_downloads';
export const DocumentFieldChangeType = 'document_field_changes';
export const DocumentFieldType = 'document_fields';
export const DocumentReportType = 'document_reports';
export const DocumentRowType = 'document_rows';
export const DocumentSectionType = 'document_sections';
export const DocumentTaskType = 'document_tasks';
export const DocumentType = 'documents';
export const DocumentTypeFieldsetType = 'document_type_fieldsets';
export const DocumentTypeFieldType = 'document_type_fields';
export const DocumentTypeRelationType = 'document_type_relations';
export const DocumentTypeSectionType = 'document_type_sections';
export const DocumentTypeType = 'document_types';
export const ExtractionTypeAdditionalExtractorType = 'extraction_type_additional_extractors';
export const ExtractionTypeType = 'extraction_types';
export const GlobalCommentType = 'comments';
export const InviteType = 'invites';
export const LogType = 'logs';
export const MasterDataType = 'master_data';
export const MeType = 'current_users';
export const OrganizationType = 'organizations';
export const PartnerType = 'partners';
export const RoleType = 'roles';
export const TagType = 'tags';
export const TargetType = 'targets';
export const TargetVersionType = 'target_versions';
export const TenantType = 'tenants';
export const TenantConfigurationType = 'tenant_configurations';
export const TimeLogReportType = 'time_log_reports';
export const TimeLogType = 'time_logs';
export const TopicType = 'topics';
export const TransformerType = 'transformers';
export const UserType = 'users';
export const VerifierType = 'verifiers';
export const WebhookEventLogType = 'logs';
export const WebhookEventType = 'events';
export const WebhookType = 'webhooks';
