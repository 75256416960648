import { Model, Attribute } from './decorators';
import { BaseApiModel } from './base';
import { MasterDataType } from '@parashift/shared/types';

export interface Sqlite3FileUpload {
  base64_file: string,
  file_name: string,
}

export interface MasterDataPlainModel {
  id: string;
  created_at: string;
  sqlite3_url: string;
  tenant_id: string;
  updated_at: string;
  uuid: string;
}

@Model({ type: MasterDataType })
export class MasterData extends BaseApiModel<MasterDataPlainModel> {

  @Attribute({ readonly: true })
  created_at: string;

  @Attribute()
  sqlite3_file: Sqlite3FileUpload;

  @Attribute({ readonly: true })
  sqlite3_url: string;

  @Attribute({ readonly: true })
  tenant_id: string;

  @Attribute({ readonly: true })
  updated_at: string;

  @Attribute({ readonly: true })
  uuid: string;
}
