import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map, Observable, of, switchMap } from 'rxjs';
import { URL } from './url';
import { CurrentTenantRegister, TenantConfigurationService } from '@parashift/shared/services';

export interface SqliteExec {
  task: 'init' | 'open' | 'exec' | 'close' | 'export' | 'import';
  filename?: string;
  command?: string;
  arrayBuffer?: ArrayBuffer;
}

@Injectable({
  providedIn: 'root'
})
export class SqliteWorkerService {

  private _databaseEstablished$ = new BehaviorSubject<boolean | 'loading'>(false);
  public readonly databaseEstablished$ = this._databaseEstablished$.asObservable();
  get databaseEstablished() { return this._databaseEstablished$.getValue(); }

  private worker!: Worker;
  private tenantId: number;

  constructor(
    private http: HttpClient,
    private tenantConfigurationService: TenantConfigurationService,
    private currentTenantRegister: CurrentTenantRegister
  ) {}

  initWorkerAndDatabase() {
    const currentTenantId = this.currentTenantRegister.tenant?.id;

    if ((this.databaseEstablished === 'loading' || this.databaseEstablished === true) && Number(currentTenantId) === this.tenantId) {
      return;
    }

    this.tenantId = Number(currentTenantId);
    this._databaseEstablished$.next('loading');

    if (this.worker) {
      this.open(this.tenantId).subscribe(() => this._databaseEstablished$.next(true));
    } else {
      if (typeof Worker !== 'undefined') {
        this.worker = new Worker(new URL('./../worker/sqlite.worker', import.meta.url), { type: 'module' });

        this.init().pipe(
          switchMap(() => this.open(this.tenantId)),
        ).subscribe(() => this._databaseEstablished$.next(true));
      } else {
        // Web workers are not supported in this environment.
        // We should set a flag which disables all MDM funcitonality then
        console.error('Your browser does not support web workers!');
        this._databaseEstablished$.error('Your browser does not support web workers!');
      }
    }
  }

  exec(command: string): Observable<{ rows: any[];  columns: any[]; }> {
    return new Observable((subscriber) => {
      this.worker.postMessage({ task: 'exec', command });
      this.worker.onmessage = ({ data }) => {
        subscriber.next(data);
        subscriber.complete();
      };
    });
  }

  exportDB(): Observable<{ byteArray: Uint8Array; name: string; }> {
    return new Observable((subscriber) => {
      this.worker.postMessage({ task: 'export' });
      this.worker.onmessage = ({ data }) => {
        subscriber.next(data);
        subscriber.complete();
      };
    });
  }

  importDB(filename: string, arrayBuffer: ArrayBuffer) {
    return new Observable((subscriber) => {
      this.worker.postMessage({ task: 'import', filename, arrayBuffer });
      this.worker.onmessage = ({ data }) => {
        if (data?.error) {
          subscriber.error(data);
        } else {
          subscriber.next(data);
        }
        subscriber.complete();
      };
    });
  }

  close() {
    return new Observable((subscriber) => {
      this.worker.postMessage({ task: 'close' });
      this.worker.onmessage = ({ data }) => {
        subscriber.next(data);
        subscriber.complete();
      };
    });
  }

  private init() {
    return new Observable((subscriber) => {
      this.worker.postMessage({ task: 'init' });
      this.worker.onmessage = ({ data }) => {
        subscriber.next(data);
        subscriber.complete();
      };
    });
  }

  private open(tenantId: number) {
    return this.tenantConfigurationService.findAll(undefined, tenantId)
      .pipe(
        map(configurations => configurations.getModels()[0]),
        switchMap(tenantConfiguration => {
          return tenantConfiguration.master_data
            ? this.getDatabaseFile(tenantConfiguration.master_data)
            // ? this.http.get(tenantConfiguration.master_data, { responseType: 'arraybuffer', headers, observe: 'body' })
            : of(null)
        }),
        // switchMap((arrayBuffer: ArrayBuffer) => {
        switchMap((arrayBuffer) => {
          return new Observable((subscriber) => {
            this.worker.postMessage({ task: 'open', arrayBuffer });
            this.worker.onmessage = ({ data }) => {
              subscriber.next(data);
              subscriber.complete();
            };
          });
        })
      );
  }

  private async getDatabaseFile(url: string) {
    const response = await fetch(url);
    return response.arrayBuffer();
  }
}
