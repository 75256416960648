import { format, parse, isValid } from 'date-fns';
import { de } from 'date-fns/locale';
import { Model, Attribute, BelongsTo, HasMany, RelationHandling } from './decorators';
import { BaseApiModel  } from './base';
import { ExtractionType } from './extraction-type.model';
import { DocumentTypeFieldType, ExtractionTypeType, DocumentTypeRelationType } from '@parashift/shared/types';
import { DocumentTypeRelation } from './document-type-relation.model';
import { Visibility } from './document-type.model';
import { CoordinateProps, Coordinates, ExtractionRule, NeighborParams, RegionSize, Vector } from './../interfaces/interfaces.index';
import { Locale } from './../interfaces/locale.interface';
import { BusinessSector } from './../interfaces/business-sector.interface';
import { CheckboxSettings } from './../interfaces/checkbox-settings.interface';

const DocumentTypeFieldStatus = {
  draft: $localize `:@@common.draft:Draft` as 'draft',
  test: $localize `:@@common.test:Test/Training` as 'test',
  production: $localize `:@@common.production:Production` as 'production',
  deactivated: $localize `:@@common.deactivated:Deactivated` as 'deactivated',
};
type DocumentTypeFieldStatus = (typeof DocumentTypeFieldStatus)[keyof typeof DocumentTypeFieldStatus];
export { DocumentTypeFieldStatus };

export enum OutputDataType {
  allowed_values = 'allowed_values',
  boolean = 'boolean',
  date = 'date',
  datetime = 'datetime',
  float = 'float',
  integer = 'integer',
  multicheckbox = 'multicheckbox',
  page_coordinates = 'page_coordinates',
  string = 'string'
}

export const OutputDataTypeLabels = {
  allowed_values: $localize `:@@common.allowed_values:Allowed values`,
  boolean: $localize `:@@common.boolean:Boolean`,
  date: $localize `:@@common.date:Date`,
  datetime: $localize `:@@common.datetime:Datetime`,
  float: $localize `:@@common.float:Float`,
  integer: $localize `:@@common.integer:Integer`,
  multicheckbox: $localize `:@@common.multicheckbox:Multi-Checkbox`,
  page_coordinates: $localize `:@@common.page_coordinates:Page coordinates`,
  string: $localize `:@@common.string:String`
};

export interface Parameter {
  [label: string]: any;
  data_type: 'int' | 'float' | 'string';
}

export interface ConfiguredVerifier {
  identifier: string;
  parameters: Parameter[];
}

export interface ConfiguredTransformer {
  identifier: string;
  parameters: Parameter[];
}

export interface NameValueSet {
  name: string;
  value: string;
}

export interface AllowedValue {
  value: string;
  label: string;
}

export interface PlainModelPattern {
  pattern: string
}

export interface PlainModelType {
  type: string;
}

export interface PlainModelDirection {
  direction: string;
}

export interface DocumentTypeFieldPlainModel {
  id: string;
  allowed_values: AllowedValue[];
  business_sectors: BusinessSector[];
  case_insensitive: boolean;
  confidence_lower_threshold: number;
  confidence_upper_threshold: number;
  checkbox_settings: CheckboxSettings[];
  confidence_threshold: [number, number];
  coordinates: Coordinates;
  coordinates_free_floating: boolean;
  coordinates_required: boolean;
  created_at: string;
  description: string;
  directions: PlainModelDirection[];
  discarded_at: string;
  extraction_type_id: string;
  extractor_attribute: string;
  fuzzy: boolean;
  identifier: string;
  input_arguments: string;
  internal: boolean;
  locales: Locale[];
  maximum_date: string;
  maximum_integer: number | string;
  minimum_date: string;
  minimum_integer: number | string;
  model: string;
  neighbor_params: NeighborParams;
  not_for_training: boolean;
  not_for_validation: boolean;
  optional: boolean;
  output_data_type: OutputDataType;
  page_number: number;
  parent_id: number;
  patterns: PlainModelPattern[];
  preprocess_parameters: NameValueSet[];
  recognition_confidence_threshold: number;
  recognition_threshold: number;
  regex_error_hint: string;
  regex_error_pattern: string;
  regex_warning_hint: string;
  regex_warning_pattern: string;
  region_size: RegionSize;
  root_id: number;
  status: DocumentTypeFieldStatus;
  tags: string[];
  tenant_id: number;
  threshold: number;
  title: string;
  training_parameters: NameValueSet[];
  transformers: ConfiguredTransformer[];
  types: PlainModelType[];
  updated_at: string;
  validation_help: string;
  vector: Vector;
  verifiers: ConfiguredVerifier[];
  visibility: Visibility;

  // Includes / Relations
  extraction_type: ExtractionType['plainModel'] | Partial<ExtractionType['plainModel']>;
  parent: DocumentTypeField['plainModel'] | Partial<DocumentTypeField['plainModel']>;
  document_type_relations: (DocumentTypeRelation['plainModel'] | Partial<DocumentTypeRelation['plainModel']>)[];
}

@Model({ type: DocumentTypeFieldType })
export class DocumentTypeField extends BaseApiModel<DocumentTypeFieldPlainModel> {

  @Attribute()
  allowed_values: AllowedValue[];

  @Attribute()
  business_sectors: BusinessSector[];

  @Attribute()
  case_insensitive: boolean;

  @Attribute()
  confidence_lower_threshold: number;

  @Attribute()
  confidence_upper_threshold: number;

  @Attribute()
  checkbox_settings: CheckboxSettings[];

  @Attribute()
  coordinates: Coordinates;

  @Attribute()
  coordinates_free_floating: boolean;

  @Attribute()
  coordinates_required: boolean;

  @Attribute()
  created_at: string;

  @Attribute()
  description: string;

  @Attribute()
  directions: string[];

  @Attribute()
  discarded_at: string;

  @Attribute()
  extraction_type_id: number;

  @Attribute()
  extractor_attribute: string;

  @Attribute()
  extraction_rules_root: ExtractionRule[];

  @Attribute()
  fuzzy: boolean;

  @Attribute()
  identifier: string;

  @Attribute()
  input_arguments: object;

  @Attribute()
  internal: boolean;

  @Attribute()
  locales: Locale[];

  @Attribute()
  maximum_date: string;

  @Attribute()
  maximum_integer: number;

  @Attribute()
  minimum_date: string;

  @Attribute()
  minimum_integer: number;

  @Attribute()
  model: string;

  @Attribute()
  neighbor_params: NeighborParams;

  @Attribute()
  not_for_training: boolean;

  @Attribute()
  not_for_validation: boolean;

  @Attribute()
  optional: boolean;

  @Attribute()
  output_data_type: OutputDataType;

  @Attribute()
  page_number: number;

  @Attribute()
  parent_id: number;

  @Attribute()
  patterns: string[];

  @Attribute()
  preprocess_parameters: NameValueSet[];

  @Attribute()
  recognition_confidence_threshold: number;

  @Attribute()
  regex_error_hint: string;

  @Attribute()
  regex_error_pattern: string;

  @Attribute()
  regex_warning_hint: string;

  @Attribute()
  regex_warning_pattern: string;

  @Attribute()
  region_size: RegionSize;

  @Attribute()
  root_id: number;

  @Attribute()
  status: DocumentTypeFieldStatus;

  @Attribute()
  tags: string[];

  @Attribute()
  tenant_id: number;

  @Attribute()
  threshold: number;

  @Attribute()
  title: string;

  @Attribute()
  training_parameters: NameValueSet[];

  @Attribute()
  transformers: ConfiguredTransformer[];

  @Attribute()
  types: string[];

  @Attribute()
  updated_at: string;

  @Attribute()
  validation_help: string;

  @Attribute()
  vector: Vector;

  @Attribute()
  verifiers: ConfiguredVerifier[];

  @Attribute()
  visibility: Visibility;

  // Includes / Relations

  @BelongsTo({ class: ExtractionTypeType })
  extraction_type: ExtractionType;

  @BelongsTo({ class: DocumentTypeFieldType, sidepostable: false })
  parent: (DocumentTypeField | Partial<DocumentTypeField>);

  @RelationHandling('destroy')
  @HasMany({ class: DocumentTypeRelationType, sidepostable: true })
  document_type_relations: (DocumentTypeRelation | Partial<DocumentTypeRelation>)[];

  getType(currentTenantId: number): 'standard' | 'individual' {
    if (!this.parent_id && this.tenant_id === currentTenantId) {
      return 'individual';
    } else {
      return 'standard';
    }
  }

  getAllowEditFlag(): boolean {
    return this.parent_id ? false : true;
  }

  getDoctypeCountByRelations(currentTenantId: number): number {
    const doctypeIds: number[] = [];
    if (this.document_type_relations && this.document_type_relations.length > 0) {
      this.document_type_relations.forEach(relation => {
        if (
          !relation.discarded_at
          && relation.document_type_field_id === Number(this.id)
          && relation.document_type_id
          && currentTenantId === this.tenant_id
          && !doctypeIds.find(id => id === relation.document_type_id)
        ) {
          doctypeIds.push(relation.document_type_id);
        }
      });
    }

    return doctypeIds.length;
  }

  get plainModel(): DocumentTypeFieldPlainModel {
    const model: DocumentTypeFieldPlainModel = this.toHash() as DocumentTypeFieldPlainModel;
    model.coordinates = this.getCoordinates(this.coordinates);
    model.directions = this.getDirections();
    model.extraction_type_id = this.extraction_type_id ? String(this.extraction_type_id) : '';
    model.maximum_date = this.getDate(this.maximum_date);
    model.minimum_date = this.getDate(this.minimum_date);
    model.input_arguments = this.getInputArguments();
    model.patterns = this.getPatterns();
    model.types = this.getTypes();
    model.confidence_lower_threshold = (this.confidence_lower_threshold === null || this.confidence_lower_threshold === undefined)
                                       ? null
                                       : this.confidence_lower_threshold;
    model.confidence_upper_threshold = (this.confidence_upper_threshold === null || this.confidence_upper_threshold === undefined)
                                       ? null
                                       : this.confidence_upper_threshold;
    model.confidence_threshold = [
      this.confidence_lower_threshold || this.confidence_lower_threshold === 0 ? this.confidence_lower_threshold * 100 : 30,
      this.confidence_upper_threshold || this.confidence_upper_threshold === 0 ? this.confidence_upper_threshold * 100 : 95
    ];
    model.recognition_confidence_threshold = (this.recognition_confidence_threshold === null || this.recognition_confidence_threshold === undefined)
                                            ? null
                                            : this.recognition_confidence_threshold;
    model.recognition_threshold = this.recognition_confidence_threshold || this.recognition_confidence_threshold === 0 ? this.recognition_confidence_threshold * 100 : 95;
    model.verifiers = this.getVerifiers();

    return model;
  }

  set plainModel(model) {
    this.customUpdates = {
      confidence_lower_threshold: setConfidenceLowerThreshold,
      confidence_upper_threshold: setConfidenceUpperThreshold,
      recognition_confidence_threshold: setRecognitionConfidenceThreshold,
      coordinates: setCoordinates,
      directions: setDirections,
      extraction_type_id: setExtractionTypeId,
      input_arguments: setInputArguments,
      minimum_date: setDate,
      maximum_date: setDate,
      patterns: setPatterns,
      transformers: setTransformersOrVerifiers,
      types: setTypes,
      verifiers: setTransformersOrVerifiers,
    };

    this.setPlainModel(model);
  }

  getVerifiers() {
    if (Array.isArray(this.verifiers)) {
      return this.verifiers.map(item => {
        if (item.identifier === 'page_number' && item.parameters[0]['page_number'] >= 0) {
          item.parameters[0]['page_number'] = Number(item.parameters[0]['page_number']) + 1;
        }

        return item;
      });
    }
    return this.verifiers;
  }

  getCoordinates(coordinates: Partial<Coordinates>): Coordinates {
    coordinates = coordinates || {};
    const convertedCoordinates: Coordinates = {} as Coordinates;

    for (const key in coordinates) {
      if (Object.prototype.hasOwnProperty.call(coordinates, key)) {
        convertedCoordinates[key as CoordinateProps] = coordinates[key as CoordinateProps];
      }
    }

    return convertedCoordinates;
  }

  getDate(date: string): string {
    if (date) {
      const parsedDate = parse(date, 'yyyy-MM-dd', new Date());
      return format(parsedDate, 'dd-MM-yyyy');
    } else {
      return date;
    }
  }

  getPatterns(): PlainModelPattern[] {
    const patterns = this.patterns || [];
    const convertedPatterns: PlainModelPattern[] = [];
    patterns.forEach(pattern => convertedPatterns.push({ pattern }));
    return convertedPatterns;
  }

  getTypes(): PlainModelType[] {
    const types = this.types || [];
    const convertedTypes: PlainModelType[] = [];
    types.forEach(type => convertedTypes.push({ type }));
    return convertedTypes;
  }

  getDirections(): PlainModelDirection[] {
    const directions = this.directions || [];
    const convertedDirections: PlainModelDirection[] = [];
    directions.forEach(direction => convertedDirections.push({ direction }));
    return convertedDirections;
  }

  getInputArguments(): string {
    if (!this.input_arguments) {
      return '';
    }
    return JSON.stringify(this.input_arguments);
  }

  getReadableDataTypeWithTransformers() {
    return ['' + this.output_data_type]
      .concat(this.transformers?.map(x => {
        const transformer = x;
        const params = transformer.parameters
          .map(p => Object.entries(p))
          .reduce((batch, all) => batch.concat(all), [])
          .map(([key, value]) => value === undefined || value === null ? key : `${key}: '${value}'`)
          .join(', ');
        return `${transformer.identifier}${params.length === 0 ? '' : `(${params})`}`;
      }))
      .join(' | ');
  }

  getDataTypeWithIdAndRootId() {
    const rootId = this.root_id ? ['root: ' + this.root_id] : [];
    return ['' + this.output_data_type]
      .concat(
        ['id: ' + this.id],
        rootId
      )
      .join(' | ');
  }
}

function setCoordinates(existingModel: BaseApiModel, updatedPlainModel: BaseApiModel['plainModel'], key: string) {
  const convertedCoordinates: {[index: string]:any} = {};

  for (const subkey in updatedPlainModel[key]) {
    if (Object.prototype.hasOwnProperty.call(updatedPlainModel[key], subkey)) {
      convertedCoordinates[subkey] = parseFloat(String(updatedPlainModel[key][subkey]));
    }
  }

  existingModel[key] = convertedCoordinates;
}

function isValidDate(date: string): boolean {
  const parsedDate = parse(date, 'dd.MM.yyyy', new Date(), { locale: de });
  return isValid(parsedDate);
}

function setDate(existingModel: BaseApiModel, updatedPlainModel: BaseApiModel['plainModel'], key: string) {
  if (updatedPlainModel[key] && isValidDate(updatedPlainModel[key])) {
    const parsedDate = parse(updatedPlainModel[key], 'dd.MM.yyyy', new Date(), { locale: de });
    existingModel[key] = format(parsedDate, 'yyyy-MM-dd');
  } else {
    existingModel[key] = null;
  }
}

function setPatterns(existingModel: BaseApiModel, updatedPlainModel: BaseApiModel['plainModel'], key: string) {
  const patterns: string[] = [];
  updatedPlainModel[key].forEach((item: PlainModelPattern) => patterns.push(item.pattern));
  existingModel[key] = patterns;
}

function setTypes(existingModel: BaseApiModel, updatedPlainModel: BaseApiModel['plainModel'], key: string) {
  const types: string[] = [];
  updatedPlainModel[key].forEach((item: PlainModelType) => types.push(item.type));
  existingModel[key] = types;
}

function setDirections(existingModel: BaseApiModel, updatedPlainModel: BaseApiModel['plainModel'], key: string) {
  const directions: string[] = [];
  updatedPlainModel[key].forEach((item: PlainModelDirection) => directions.push(item.direction));
  existingModel[key] = directions;
}

function setConfidenceLowerThreshold(existingModel: BaseApiModel, updatedPlainModel: BaseApiModel['plainModel'], key: string) {
  if (updatedPlainModel['confidence_threshold'] && Array.isArray(updatedPlainModel['confidence_threshold'])) {
    existingModel[key] = updatedPlainModel['confidence_threshold'][0] / 100;
  } else {
    existingModel[key] = updatedPlainModel[key];
  }
}

function setConfidenceUpperThreshold(existingModel: BaseApiModel, updatedPlainModel: BaseApiModel['plainModel'], key: string) {
  if (updatedPlainModel['confidence_threshold'] && Array.isArray(updatedPlainModel['confidence_threshold'])) {
    existingModel[key] = updatedPlainModel['confidence_threshold'][1] / 100;
  } else {
    existingModel[key] = updatedPlainModel[key];
  }
}

function setRecognitionConfidenceThreshold(existingModel: BaseApiModel, updatedPlainModel: BaseApiModel['plainModel'], key: string) {
  if (updatedPlainModel['recognition_threshold']) {
    existingModel[key] = updatedPlainModel['recognition_threshold'] / 100;
  } else {
    existingModel[key] = updatedPlainModel[key];
  }
}

function setExtractionTypeId(existingModel: BaseApiModel, updatedPlainModel: BaseApiModel['plainModel'], key: string) {
  existingModel[key] = updatedPlainModel[key] ? Number(updatedPlainModel[key]) : null;
}

function setInputArguments(existingModel: BaseApiModel, updatedPlainModel: BaseApiModel['plainModel'], key: string) {
  if (updatedPlainModel[key]) {
    existingModel[key] = JSON.parse(updatedPlainModel[key]);
  } else {
    existingModel[key] = {};
  }
}

function setTransformersOrVerifiers(existingModel: BaseApiModel, updatedPlainModel: BaseApiModel['plainModel'], key: string) {
  const newPlainModel: ConfiguredTransformer[] | ConfiguredVerifier[] = [];

  updatedPlainModel[key].forEach((model: ConfiguredTransformer | ConfiguredVerifier) => {
    const newModel = { ...model };
    if (newModel.parameters && Array.isArray(newModel.parameters)) {
      const parameters = newModel.parameters.map(param => {
        for (const [attr] of Object.entries(param)) {
          if (attr !== 'data_type' && (param.data_type === 'float' || param.data_type === 'int')) {
            param[attr] = String(param[attr]).replace('_', '0');
            param[attr] = Number(param[attr]);
          }
          if (attr === 'page_number' && Number(param[attr]) > 0 ) {
            param = { page_number: Number(param[attr]) - 1, data_type: 'int' };
          }
        }

        return param;
      });
      newModel.parameters = [...parameters];
    }
    newPlainModel.push(newModel);
  });

  updatedPlainModel[key] = newPlainModel;
  existingModel[key] = updatedPlainModel[key];
}
