<div>
  <ng-container *ngFor="let field of field.fieldGroup; let i = index">
    <div>
      <div *ngIf="!field.hide" class="repeat-section document-type-section card form-card">
        <div class="card-header" *ngIf="!model[i].fieldset_config_mode">
          <div class="limit-width">
            <div class="form-group section-title">
              <label [for]="'section-title-' + i" i18n="@@common.title">Title</label>
              <input [disabled]="!model[i].allow_edit" class="form-control" [(ngModel)]="model[i].title" [id]="'section-title-' + i">
            </div>

            <div class="repeat-section-remove">
              <button *ngIf="model[i].allow_edit"
                      type="button"
                      class="close"
                      aria-label="Remove"
                      (click)="removeSection(i)"
                      [disabled]="documentTypeHelperService.disableForm"
                      [hidden]="props.fieldsOnly === true">
                <fa-icon [icon]="['fas', 'times-circle']"
                        transform="shrink-3"
                        [fixedWidth]="true"
                        aria-hidden="true"
                        i18n-tooltip="@@common.remove"
                        tooltip="Remove"
                        container="body"
                        containerClass="danger">
                </fa-icon>
              </button>
              <button *ngIf="model[i].allow_edit && model.length > 1"
                      type="button"
                      class="close up"
                      aria-label="Move up"
                      (click)="moveUp(i)"
                      [disabled]="documentTypeHelperService.disableForm || i === 0">
                <fa-icon [icon]="['fas', 'chevron-circle-up']"
                        transform="shrink-3"
                        [fixedWidth]="true"
                        aria-hidden="true"
                        i18n-tooltip="@@common.move_up"
                        tooltip="Move up"
                        container="body">
                </fa-icon>
              </button>
              <button *ngIf="model[i].allow_edit && model.length > 1"
                      type="button"
                      class="close down"
                      aria-label="Move down"
                      (click)="moveDown(i)"
                      [disabled]="documentTypeHelperService.disableForm || i === model.length - 1">
                <fa-icon [icon]="['fas', 'chevron-circle-down']"
                        transform="shrink-3"
                        [fixedWidth]="true"
                        aria-hidden="true"
                        i18n-tooltip="@@common.move_down"
                        tooltip="Move down"
                        container="body">
                </fa-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="card-body">
          <formly-field [field]="field"></formly-field>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div class="card form-card document-type-section" *ngIf="!props.labelAdd ? false : props.allowEdit()">
  <div class="card-body">
    <div class="d-flex" style="max-width: 790px">
      <div *ngIf="props.labelAdd" class="repeat-section-button me-1" [hidden]="((props.maxItems || props.maxItems === 0) && props.maxItems <= model.length) || props.fieldsOnly === true">
        <button type="button" class="btn btn-dark" [ngClass]="props.btnClass ? props.btnClass : 'btn-sm'" (click)="add()" [disabled]="documentTypeHelperService.disableForm">
          <fa-icon
            [icon]="['fas', 'layer-plus']"
            [fixedWidth]="true"
            aria-hidden="true"
            container="body">
          </fa-icon>
          {{ props.labelAdd }}
        </button>
      </div>
      <ng-container *ngIf="props.allowLlmConfig">
        <div class="repeat-section-button ms-1">
          <button type="button" class="btn btn-dark" [ngClass]="props.btnClass ? props.btnClass : 'btn-sm'" (click)="addLlmConfig()" [disabled]="documentTypeHelperService.disableForm">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="fa-icon" aria-hidden="true" focusable="false"><path fill="currentColor" d="M32 256c0-9.4 5.4-17.9 13.9-21.8l218.6-101c14.9-6.9 32.1-6.9 47 0c50.9 23.5 101.7 47 152.6 70.5c-6.3 5.3-11.3 12.3-14.3 20.3l-26.7 71.1L352 321.8c-19.1 7.1-31.8 25.3-32 45.7l0 .9c0 2.1 .2 4.2 .5 6.3l-9 4.1c-14.9 6.9-32.1 6.9-47 0L45.9 277.8C37.4 273.9 32 265.4 32 256zm0 128c0-9.4 5.4-17.9 13.9-21.8l53.2-24.5 152 70.2c23.4 10.8 50.4 10.8 73.8 0l0-.1 9.9-4.6c4.8 4.7 10.6 8.5 17.2 11l71.1 26.7 4.6 12.2L311.5 506.8c-14.9 6.9-32.1 6.9-47 0L45.9 405.8C37.4 401.9 32 393.4 32 384zm320-16c0-7.2 4.5-13.7 11.2-16.2L448 320l31.8-84.8c2.5-6.7 9-11.2 16.2-11.2s13.7 4.4 16.2 11.2L544 320l84.8 31.8c6.7 2.5 11.2 9 11.2 16.2s-4.4 13.7-11.2 16.2L544 416l-31.8 84.8c-2.5 6.7-9 11.2-16.2 11.2s-13.7-4.4-16.2-11.2L448 416l-84.8-31.8c-6.7-2.5-11.2-9-11.2-16.2zM384 80c0-13.3 10.7-24 24-24l32 0 0-32c0-13.3 10.7-24 24-24s24 10.7 24 24l0 32 32 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-32 0 0 32c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-32-32 0c-13.3 0-24-10.7-24-24z"/></svg>
            {{ props.labelAddLlmConfig }}
          </button>
        </div>
      </ng-container>
      <div *ngIf="model && model.length > 0 && props.showRemoveAllSectionsButton">
        <button type="button" class="btn btn-danger btn-sm" (click)="removeAllSections()" [disabled]="documentTypeHelperService.disableForm">{{ props.labelRemoveAllSections }}</button>
      </div>
    </div>
  </div>
</div>
