import { AbstractControl,ValidationErrors, ValidatorFn } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

export function AllowedCharactersValidatorFormly(control: AbstractControl, field: FormlyFieldConfig, options = {}): ValidationErrors {
  return AllowedCharactersValidator(options['pattern'])(control);
}

export function AllowedCharactersValidator(pattern: string): ValidatorFn {
  return (control: AbstractControl) : ValidationErrors | null => {
    const value = control.value;
    const regex = new RegExp(pattern);
    return !value || regex.test(value) ? null : { 'allowed-characters': true };
  }
}
