import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { Env } from '@parashift/shared/models';
import { RegionService } from './region.service';
import { ENVIRONMENT_CONFIG } from '@parashift/shared/environment-config';
import { ENVIRONMENT_CONFIG_PATH } from '@parashift/shared/constants';

export enum Endpoint {
  api = 'api',
  id = 'id',
  individual_extraction = 'individual_extraction',
  llm = 'llm',
  webhooks = 'webhooks',
  sentry = 'sentry'
}

export class Endpoints {

  constructor(
    private _endpoints: Env['endpoints'],
    private regionService: RegionService
  ) {}

  get version(): string { return this._endpoints.version; }
  get individual_extraction_version(): 'app' { return this._endpoints.individual_extraction_version; }
  get api(): string { return this.setRegion(Endpoint.api); }
  get id(): string { return this._endpoints.id; }
  get individual_extraction(): string { return this.setRegion(Endpoint.individual_extraction); }
  get llm(): string { return this.setRegion(Endpoint.llm); }
  get webhooks(): string { return this.setRegion(Endpoint.webhooks); }
  get sentry(): string { return this.setRegion(Endpoint.sentry); }

  get global() { return this._endpoints.global; }

  private setRegion(endpoint: Endpoint): string {
    const url = this.regionService.region && this.regionService.region !== 'ZZ' ? this._endpoints[endpoint] : this._endpoints.global[endpoint];

    if (url.includes('{region}')) {
      return url.replace('{region}', this.getRegionReplacement());
    }

    return url;
  }

  private getRegionReplacement() {
    return this.regionService.region === 'ZZ' ? '' : '-' + this.regionService.region.toLowerCase();
  }
}

@Injectable({
  providedIn: 'root'
})
export class EnvService {

  private _endpoints: Endpoints;
  private http: HttpClient = inject(HttpClient);
  private environment: Env = inject(ENVIRONMENT_CONFIG);
  private regionService = inject(RegionService);

  constructor() {
    // FD: TODO: As soon as devops provide ENVs in /assets/environment/config.json file we fetch it with APP_INITIALIZER in app.config.ts and apply it
    this._endpoints = new Endpoints(this.environment.endpoints, this.regionService);
  }

  get production(): boolean { return this.environment.production; }
  get env(): 'dev' | 'tst' | 'prod' { return this.environment.env; }
  get version(): string { return this.environment.version; }
  get host(): string { return this.environment.host; }
  get id_node(): string { return this.environment.id_node; }
  get endpoints(): Env['endpoints'] { return this._endpoints; }
  get enable_sentry(): boolean { return this.environment.enable_sentry; }
  get log(): boolean { return this.environment.log; }
  get list_default_page_size(): number { return this.environment.list_default_page_size; }
  get userflow(): Env['userflow'] { return this.environment.userflow; }
  get reset(): Env['reset'] { return this.environment.reset; }
  get active_storage_attachment_poll_minutes(): number { return this.environment.active_storage_attachment_poll_minutes; }
  get llm_field_extractor_id(): number { return this.environment.llm_field_extractor_id; }

  getConfig(): Observable<Env> {
    return this.http.get<Env>(ENVIRONMENT_CONFIG_PATH).pipe(
      tap(() => {
        // FD: TODO: As soon as devops provide ENVs in /assets/environment/config.json file we fetch it with APP_INITIALIZER in app.config.ts and apply it
        // this.environment = config;
        // this._endpoints = new Endpoints(this.environment.endpoints);
      })
    );
  }
}
