import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ListFilter, MasterData, UrlVars } from '@parashift/shared/models';
import { MasterDataType } from '@parashift/shared/types';
import { ServiceDecorator } from './decorators';
import { BaseApiService } from './base';
import { QueryParamsService } from '../query-params.service';
import { Endpoint } from '../env.service';

@Injectable({
  providedIn: 'root'
})
@ServiceDecorator({
  model: () => MasterData,
  endpointUrl: MasterDataType,
})
export class MasterDataService extends BaseApiService<MasterData> {
  className = MasterDataService;

  constructor(
    http: HttpClient,
    queryParamsService: QueryParamsService
  ) {
    super(http, queryParamsService);
    this.baseUrl = Endpoint.individual_extraction;
    this.apiVersion = this.environment.endpoints.individual_extraction_version
  }

  getLatest(tenantId: number) {
    const listFilter = new ListFilter({ extra_fields: { master_data: ['sqlite3_url'] }});
    const params = this.queryParamsService.getParams(listFilter, true);
    const urlVars: UrlVars = { postfix: `latest?${params}` };
    return this.findRecord('', undefined, tenantId, urlVars);
  }
}
