import { Injectable } from '@angular/core';
import { SqliteWorkerService } from 'components/master-data-management/services/sqlite-worker.service';
import { map, Observable, tap } from 'rxjs';

@Injectable()
export class MdmConfigService {

  constructor(private sqliteWorkerService: SqliteWorkerService) {}

  getTableNames() {
    const statement = `SELECT name FROM sqlite_master
                       WHERE type='table' AND name NOT LIKE 'sqlite_%'
                       ORDER BY name;`;
    return this.sqliteWorkerService.exec(statement);
  }

  searchTables(term: string): Observable<string[]> {
    return this.getTableNames()
      .pipe(
        map(({ rows }) => rows.map(row => row.name)),
        map(tables => {
          if (!term) {
            return tables;
          }
          term = term.toLowerCase();
          return tables.filter(table => table.toLowerCase().includes(term));
        })
      );
  }

  getTableColumns(tableName: string) {
    const statement = `SELECT * FROM PRAGMA_TABLE_INFO('${tableName}');`;
    return this.sqliteWorkerService.exec(statement);
  }

  searchColumns(table: string, term: string): Observable<string[]> {
    return this.getTableColumns(table)
      .pipe(
        map(({ rows }) => rows.map(row => row.name)),
        map(tableColumns => {
          if (!term) {
            return tableColumns;
          }
          term = term.toLowerCase();
          return tableColumns.filter(col => col.toLowerCase().includes(term));
        })
      );
  }
}
